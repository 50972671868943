import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { InternalNavigation } from '@mdc/services';
import { InternalLink } from '@mdc/ui';

import './ThankYou.scss';


const ThankYou = ({ user, className, subscriptions, transactionId, addPageRouteChange }) => {
    const { t, ready } = useTranslation();
    const [userData, setUserData] = useState([]);

    const numberWithComma = (number) => {
        return number.toLocaleString();
    };

    useEffect(() => {
        addPageRouteChange(subscriptions);
    }, [transactionId, subscriptions]);

    useEffect(() => {
        if (user && (!userData || userData !== user)) {
            setUserData(user);
        }
    }, [user]);

    const userLicenses = useMemo(() => {
        if (!userData && (subscriptions && subscriptions[0]?.receiptUrl === null)) {
            return;
        }

        // eslint-disable-next-line camelcase
        const purchaseInformation = subscriptions || userData?.license?.purchase_information;

        return purchaseInformation?.map((plan) => {
            const { tier, type } = plan;
            return { tier, type };
        });
    }, [userData, subscriptions]);

    const userLicensesDom = useMemo(() => {
        const PLAN_NAMES = {
            'prevention': t('Prevention API'),
            'reputation': t('Reputation API'),
            'sandbox': t('Sandbox API'),
            'analysis': t('Sandbox API'),
            'threat_intel_search': t('Threat Intelligence API')
        };

        if (!userLicenses) {
            const space = 10;
            const rowHeight = 50;
            const numRows = 3;
            const lineWidth = '100%';
            let keyCounter = 0;

            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ paddingTop: '20px' }}>
                        <ContentLoader
                            className={'contentLoader'}
                            speed={1}
                            height={numRows * (rowHeight + space)}
                            width={'100%'}
                            primaryColor='#EDEEF3'
                            secondaryColor='#f3f3f3'
                        >
                            {Array(numRows).fill('').map((_value, indexName) => (
                                <rect
                                    key={keyCounter++}
                                    x='0'
                                    y={indexName * (rowHeight + space)}
                                    rx='4'
                                    ry='4'
                                    width={lineWidth}
                                    height={rowHeight}
                                />
                            ))}
                        </ContentLoader>
                    </div>
                </div>
            );
        }

        return userLicenses.map((license) => {
            const planTier = license?.tier?.split(' ')[1];
            const planName = license?.tier?.split(' ')[0];
            return <li
                key={license?.id}
                className='planPrice'
            >
                <span className="planLimit">{PLAN_NAMES[planName]}</span>
                <div className="float-right">
                    {t('Daily limit:')}{' '}
                    <span className="pricingPlanPrice">{numberWithComma(planTier)}</span>
                </div>
            </li>;
        });
    }, [userLicenses]);

    if (typeof userData === 'undefined') {
        InternalNavigation.navigation('/store');
    }

    if (!ready) {
        return;
    }

    return <section className='thankYouWrapper'>
        <Row className={className}>
            <Col xs={12} className='text-center'>
                <span className="apiSymbol d-block">
                    <i className="icon-cart"></i>
                </span>
                <h1>{t('Thank you for purchasing from our online store! Your license now includes:')}</h1>
            </Col>
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }} className="text-center">
                <div>
                    {userLicensesDom}
                </div>
            </Col>
        </Row>
        <Row className='text-center'>
            <Col>
                <InternalLink to="/account" className="btn btn-primary" type="button">{t('MY ACCOUNT')}</InternalLink>
            </Col>
        </Row>
    </section>;
};

ThankYou.propTypes = {
    user: PropTypes.any,
    className: PropTypes.string,
    subscriptions: PropTypes.array,
    transactionId: PropTypes.string,
    addPageRouteChange: PropTypes.func
};

export default ThankYou;
